/**
 * keyEscape, escape key close details element
 * @param {object} event
 * @returns
 *
 */
const observerOptions = {
    // root: ctx..parentElement,
    // rootMargin: "200px 0px 200px 0px",
    threshold: [0, 0.2] // fire callback when not visible, and visible 20%
}

const checkMeasure = (observerOptions.threshold[1]) ? observerOptions.threshold[1] : observerOptions.threshold[0]

const observerCallback = (entries, observer) => {
    entries.forEach((entry) => {
        /** --- onViewportEnter --- */
        if (entry.intersectionRatio >= checkMeasure && entry.isIntersecting) {
            if (entry.target.hasEntered === false || !entry.target.hasEntered) entry.target.dispatchEvent(self.Decimal.dispatches.enter)

            entry.target.hasEntered = true
        }

        /** --- onViewportExit --- */
        if (!entry.isIntersecting && entry.target.hasEntered === true) {
            if (entry.target.resetOnExit === true) entry.target.hasEntered = false
            if (entry.target.resetOnExit !== true) observer.unobserve(entry.target) // Strip observe

            entry.target.dispatchEvent(self.Decimal.dispatches.exit)
        }
    })
}

export const setIntersectionObserver = (options = observerOptions, callback = observerCallback) => {
    return new IntersectionObserver(callback, options)
}

export const useIntersectionObserver = (ctx, el, options = observerOptions) => {
    /** --- Grab events objects --- */
    const element = el || ctx.element

    self.Decimal.events = self.Decimal.events || {}
    self.Decimal.events.observed = self.Decimal.events.observed || []
    self.Decimal.observer = self.Decimal.observer || setIntersectionObserver(options)

    if (ctx.onViewportEnter || ctx.onViewportExit) {
        self.Decimal.events.observed.push(element)
        self.Decimal.observer.observe(element)
    }
    if (ctx.onViewportEnter) element.addEventListener('viewportEnter', ctx.onViewportEnter.bind(ctx))
    if (ctx.onViewportExit) element.addEventListener('viewportExit', ctx.onViewportExit.bind(ctx))
}
